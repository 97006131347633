// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/AmountAdjuster.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/AmountAdjuster.tsx");
  import.meta.hot.lastModified = "1727786050000";
}
// REMIX HMR END

import React, { useEffect, useState } from 'react';
export function AmountAdjuster({
  qtyInCart,
  baseUnitOfMeasure,
  inCart,
  addToCart,
  adjustInCart,
  deleteFromCart,
  cssPostFix,
  showUnit
}) {
  _s();
  const [quantityOverwrite, setQuantityOverwrite] = useState(null);
  useEffect(() => {
    setQuantityOverwrite(null);
  }, [qtyInCart]);
  const increaseQuantity = () => {
    if (!inCart) {
      if (baseUnitOfMeasure === 'KG') {
        addToCart(0.25);
      } else {
        addToCart(1);
      }
    } else {
      if (baseUnitOfMeasure === 'KG') {
        adjustInCart(qtyInCart + 0.25);
      } else {
        adjustInCart(qtyInCart + 1);
      }
    }
  };
  const decreaseQuantity = () => {
    if (inCart) {
      let delta = 1;
      if (baseUnitOfMeasure === 'KG') {
        delta = 0.25;
      }
      let newQuantity = qtyInCart - delta;
      if (newQuantity < 0) {
        newQuantity = 0;
      }
      if (newQuantity === 0) {
        deleteFromCart();
      } else {
        adjustInCart(newQuantity);
      }
    }
  };
  const setQuantity = quantity => {
    if (!inCart) {
      if (quantity > 0) {
        addToCart(quantity);
      }
    } else {
      adjustInCart(quantity);
    }
  };
  return <div className="input-and-checkbox-container">
      <div className={`input-group${cssPostFix}`}>
        <button type="button" onClick={decreaseQuantity}>
          -
        </button>
        <input type="number" step={baseUnitOfMeasure === 'KG' ? '0.010' : '1'} //Change 1 here to 0.1 for KG
      min="0" value={quantityOverwrite !== null ? quantityOverwrite : baseUnitOfMeasure === 'KG' ? qtyInCart.toFixed(3) : qtyInCart.toString()} onChange={e => {
        setQuantityOverwrite(e.target.value);
      }} onBlur={e => {
        const inputValue = e.target.value;
        if (inputValue === '') {
          setQuantity(0);
          setQuantityOverwrite(0);
        } else {
          const formattedValue = baseUnitOfMeasure === 'KG' ? parseFloat(inputValue).toFixed(3) : parseInt(e.target.value, 10);
          setQuantity(formattedValue);
        }
      }} />
        {showUnit && <div className="quantity-text"> {baseUnitOfMeasure}</div>}
        <button type="button" onClick={increaseQuantity}>
          +
        </button>
      </div>
    </div>;
}
_s(AmountAdjuster, "7Rp0vU8bQFc80GwjgZo1CMvsZSE=");
_c = AmountAdjuster;
var _c;
$RefreshReg$(_c, "AmountAdjuster");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;